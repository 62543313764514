import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { headerData } from '../../../data/about-db';

export default function AboutHeader() {
  return (
    <section className="about-header">
      <Container className="about-header-container customer-about">
        <Row>
          <Col className="text-center col-12 col-md-6 offset-md-3">
            <h2>{headerData[0].name}</h2>
            <p className="text-start custom-text-justify">{headerData[0].text}</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
